<template>
  <div class="Payment">
    <div class="content" align="center">
      <el-steps :active="3" align-center>
        <el-step title="选择产品"></el-step>
        <el-step title="确认订单信息"></el-step>
        <el-step title="下单支付"></el-step>
      </el-steps>

      <i class="el-icon-success tip-image"></i>
      <div>订单提交成功！请尽快支付！</div>
      <div>
        共1张订单，<span
          class="order-center"
          @click="$router.push({ name: 'PrintOrder' })"
          >订单中心</span
        >。请在当天内完成支付，待支付：{{ payAmount }}
      </div>
      <el-tabs type="border-card" v-model="payChannel">
        <el-tab-pane label="微信支付" name="1">
          <div ref="qrcode1"></div
        ></el-tab-pane>
        <el-tab-pane label="支付宝支付" name="2" v-if="false"
          ><div ref="qrcode2"></div
        ></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import { printordercheckStatus, printorderorderPayment } from '@/api/cubeclient'
import QRCode from 'qrcodejs2'
export default {
  data () {
    return {
      orderCode: [],
      payChannel: '1',
      url: '',
      payAmount: '',
      payTime: undefined
    }
  },
  mounted () {
    this.init()

    this.setIntervalIndex = setInterval(async () => {
      const res = await printordercheckStatus(this.orderCode)
      if (res.code != 200) {
        this.$message({
          message: res.msg,
          type: 'warning'
        })
        clearInterval(this.setIntervalIndex)
        return
      }
      if (res.data[0].orderStatus == '2') {
        this.$router.push({ name: 'PrintOrder' })
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.setIntervalIndex)
    clearInterval(this.reDrawTime)
  },
  created () {
    this.reDrawTime = setInterval(() => {
      this.$refs[`qrcode${this.payChannel}`].innerHTML = ''
      this.init()
    }, 1000 * 60 * 10)
  },
  components: {
  },
  methods: {

    async init () {
      this.orderCode = this.$route.params.orderCode
      const res = await printorderorderPayment({
        payType: this.payChannel,
        orderNos: this.orderCode
      })
      this.url = res.data.codeUrl
      this.payAmount = res.data.payAmount
      // eslint-disable-next-line no-new
      new QRCode(this.$refs[`qrcode${this.payChannel}`], {
        text: this.url,
        width: 130,
        height: 130,
        colorDark: 'black', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L// 容错率，L/M/H
      })
    }
  },
  watch: {
    async payChannel (NV, OV) {
      this.$refs[`qrcode${NV}`].innerHTML = ''
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.Payment {
  width: 100%;
  display: flex;
  justify-content: center;
  .content {
    width: 1200px;
    padding-top: 20px;
    .tip-image {
      color: rgb(102, 170, 0);
      font-size: 100px;
    }
    .order-center {
      color: rgb(0, 119, 255);
      cursor: pointer;
    }
  }
}

.product-item {
  float: left;
  width: 226px;
  height: 400px;
  background: rgb(0, 160, 189);
  margin-left: 20px;
  margin-bottom: 40px;
}
</style>
